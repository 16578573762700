import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "歯科医院の業務効率を高める Dentis のサブカルテ機能開発について聞く",
  "date": "2023-10-30T15:37:32.000Z",
  "slug": "entry/2023/10/31/003732",
  "tags": [],
  "hero": "./2023-10-31.png",
  "heroAlt": "2023/10"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`みなさん、こんにちは。エンジニアの新居です。今回は今年の 3 月にリリースされた `}<a parentName="p" {...{
        "href": "https://dentis-cloud.com/"
      }}>{`Dentis`}</a>{` のサブカルテ機能について、開発したメンバーに話を聞いてみました!`}</p>
    <h1>{`対談メンバー紹介`}</h1>
    <h2>{`Dentis 開発メンバーの紹介`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`:
よろしくお願いします。早速ですが、メドレーに入社するまでや、入ってからどんな事をしていたのかを話してもらえればと思います。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/"
      }}>{`CLINICS`}</a>{` のリリース前から、ロゴデザインや UI デザインに携わったのがきっかけでメドレーに入社しました。入社してからは、CLINICS アプリや CLINICS カルテ、薬局向けシステム `}<a parentName="p" {...{
        "href": "https://pharms-cloud.com/"
      }}>{`Pharms`}</a>{` などのプロダクトをこのメンバーと一緒に開発していました。今は歯科医院向けの Dentis のデザインや企画、プロモーションなどに関わっています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/107d5e4b9953513038380f1ea578e9d3/21b4d/_note010_dev_002.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAACXElEQVQozzXO3U9ScRzH8fNPtG5aoAFKRI9bSelW3dRFF21a62F1UxclWxc1b2pzPalNp1kxkRTPCUFJ4uGg+JCORSA2ZaIjR1qZgcLh/M45nPP7nXOAK5uytvfN9+K1zxejcls0nREE1njvzr69e47qdfpqzc0LZ14/btzMpKWCzEHI7MYiyJSDEEBE8RCj6QzHgVR6/VzdaY1KVa1WdTU/int7Z/qeb6yvyqUSByG7K1mEGBGVT4BEGiIMAAowVJ4H16821J+vaX1wey1CZsL2FbKHTq1KxSKPdgAHYR4hVkRsGUOR5gUMMFSOzhSKot1OGK9c5Of9v2e9zHIg4XqzOh8WS6U8QhzaXRZ38P+3YS6fx2iQZdicXEDFkmS8eyuMP/3zhfgZsketL37E5ra3twVJZAQeCAKAAi2U42mBpzgW4/Igm9vy+jwPm5qu1V9e8r9dm7J+95g2Qs7YtNPmGE5ROaFYZESJlWRGlBkks6LMigUGSRhFb903NlYc0Cgq1Aql6n1H89+IKzLYkk3MLE7032i4NOgg0pvryeTySjKeSCwsLUYXY6GFb8G5yCQWGPcfOnzs+IlTOt2R/YrKGkNtfCH6Kx6Kzji/fh6eD7mCY/ikxzLxyRwYMY06u0cdnaS93Wtrc+MvMbOlR6PVG2rPqjXaqmqdQqmymM1oIxaetAUDxJTXOuHpD7j7yI9m39A7n6Pb5+giHR2kvd1ja8Nwwlql1Z801GkP6isq1UqlCu98Apb9wTF8mhwYd/f7Rizuod6RQdMw0eXE213EKzfR6vnQ4hp49g/ND6g/o2qeOwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " note010 dev 002",
            "title": " note010 dev 002",
            "src": "/static/107d5e4b9953513038380f1ea578e9d3/c1b63/_note010_dev_002.png",
            "srcSet": ["/static/107d5e4b9953513038380f1ea578e9d3/5a46d/_note010_dev_002.png 300w", "/static/107d5e4b9953513038380f1ea578e9d3/0a47e/_note010_dev_002.png 600w", "/static/107d5e4b9953513038380f1ea578e9d3/c1b63/_note010_dev_002.png 1200w", "/static/107d5e4b9953513038380f1ea578e9d3/21b4d/_note010_dev_002.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`前田さん`}</em></p>
    <p><strong parentName="p">{`大岡`}</strong>{`: ソーシャルゲームを 6~7 年開発していたのですが、人材プラットフォームの CTO である稲本が中学の同級生で、彼の記事を読んだのがきっかけでメドレーに入社しました。入社してからは CLINICS カルテや iOS アプリの開発などをやっていました。その後は前田と同じで Pharms の開発をしてから、現在は Dentis を開発しています。他には電子処方箋の実証事業の開発などもしていたりしました。現在は Web のフロントエンド中心に開発をしています。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8069c17629bec937d87a768846b125e1/21b4d/_note010_dev_003.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACPklEQVQoz3XS30uTURzH8f0T/gPhjVhQpujc3Oam+KMIKgltxqJush9G0Y1XRgQFFYSSkUJkNwZ5YUWxSivot2RK4EUg1lzqfj17fmzP9jx7Nt7xnLmLog58+Zxz4Lz4nsNxqFoaTZPRNIVMRhVZmYt1VsMAVr69Z+bGIPsCTqqqqjjQ00PONMgVLPJWEcMqYloWjjKo/AVupY3qWVKyRGzhKck3t5m8PEBwj5vttTWsrkUoAjnTFJhVKtngv7srz2U0Pcv82zCPR4aYv3+JmSsDjA4e5MyxIHFJolAqCtCwLAr/AyulKBJaTie6/IWVJ3f5+XyChVvneTEcIvzwHiVAN/JkTRPdLJAXV1blPxG1/AT2viTFUbMacizK+utpIuE7vLp2gunho3xfXhRgrlBAN02yhkHOLNhgmgpqdysrKRSlvKfnNBLJGGvRH7x7MMrs9ZM8uniYZ1ePY+QzArShCminQ1FT4rCqbnWVjpNMxtAyMhMTYxwJHiIUCtK3v4vw2BBzN08zdnYvF86dYj0exx6qrpdBw7DBJIoqCczOWPwXiUSUqalJ6nbtoLmpHp/HhdPZSEd7gE5/Cw27d1JbW0Mo1M+Hzx/Ft8nk82VQVmwwJSotJ9jcjLC09Ine3h5afS10dbQR8PtoC7Ti9/toamoUeHubn2ZnI/UNdbycmxV/cjMRx2EjNmpXIrnBxsYq4+MjeD1uurs66O7uxOt1Ewi0CtTrcYn0eNy4XE6qq7fR399HZG2Vr4vz/AacuIll3zZNkAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " note010 dev 003",
            "title": " note010 dev 003",
            "src": "/static/8069c17629bec937d87a768846b125e1/c1b63/_note010_dev_003.png",
            "srcSet": ["/static/8069c17629bec937d87a768846b125e1/5a46d/_note010_dev_003.png 300w", "/static/8069c17629bec937d87a768846b125e1/0a47e/_note010_dev_003.png 600w", "/static/8069c17629bec937d87a768846b125e1/c1b63/_note010_dev_003.png 1200w", "/static/8069c17629bec937d87a768846b125e1/21b4d/_note010_dev_003.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<em parentName="p">{`大岡さん`}</em></p>
    <p><strong parentName="p">{`宮内`}</strong>{`: 元同僚が働いていたので、メドレーにリファラルで入社しました。CLINICS オンライン診療の立ち上げをやった後は他の 2 人と同じで Pharms の開発をして、現在は Dentis の開発という感じです。今は主にサーバサイドの開発を中心に業務をしています。`}</p>
    <h1>{`Dentis について`}</h1>
    <h2>{`Dentis 誕生まで`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございました。では、Dentis についてお聞きしたいのですが、どんなきっかけで作られたサービスなんですか?`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: 当初は、CLINICS カルテをベースとした歯科医院向けの予約システムを開発していたのですが、歯科レセコン（電子カルテの機能のうち主に会計など診療報酬の計算をするソフトウェア）のソースコードを`}<a parentName="p" {...{
        "href": "https://www.daiwair.co.jp/td_download.cgi?c=4480&i=2036759"
      }}>{`取得`}</a>{`するタイミングも重なり、予約システムだけではなく、レセコン一体型のシステムとして、本格的に開発をスタートしました。`}</p>
    <p><em parentName="p">{`詳しくは`}<a parentName="em" {...{
          "href": "https://note.com/medley/n/nec25af1f8974"
        }}>{`こちらの記事`}</a>{`を参照ください。`}</em></p>
    <p><strong parentName="p">{`新居`}</strong>{`: なるほど。そんな形で開発が始まった Dentis ですが、コロナ禍で途中で`}<a parentName="p" {...{
        "href": "https://note.com/medley/n/nce07196a36e4"
      }}>{`作り直していますよね`}</a>{`。再開してからは 1 から開発したんですか?`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: はい、CLINICS をベースに開発していたものの、歯科医院へのヒアリングなどを実施、調査をしていくうちに医科と歯科では業務オペレーションが異なり予約システムとしては不十分な機能だったことが判明して。特に予約部分などはコンセプトから考え直して UI を一から作り直しました。`}</p>
    <p><strong parentName="p">{`宮内`}</strong>{`: 途中で Pharms を開発してリリースしたことにより、そこで使った設計や技術などを応用できたというプラスの側面もあり、再度作り直しています。`}<a parentName="p" {...{
        "href": "https://nextjs.org/"
      }}>{`Next.js`}</a>{` を導入したりなどですね。`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: 以前の Dentis はパイロット版として提供をしていたこともあり、しっかり作り変える後押しになったかもしれないです。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: そうすると開発自体も以前よりも、やりやすくもなったんですね。スムーズに開発できるようになったおかげで、 `}<a parentName="p" {...{
        "href": "https://www.j-platpat.inpit.go.jp/c1800/PU/JP-7335412/9F8179D09679876150E1B0ABB942C3F3D24F71E278BC7E6A2B456B2EEB2032B2/15/ja"
      }}>{`特許`}</a>{`も取得できる UI ができたわけですね。`}</p>
    <h2>{`Dentis のアーキテクチャについて`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 現在の Dentis のアーキテクチャはどんなものになっているんでしょう。`}</p>
    <p><strong parentName="p">{`宮内`}</strong>{`: サーバサイドは `}<a parentName="p" {...{
        "href": "https://rubyonrails.org/"
      }}>{`Rails`}</a>{` アプリケーションになっています。そこにプラスして `}<a parentName="p" {...{
        "href": "https://graphql.org/"
      }}>{`GraphQL`}</a>{` を使用しています。フロントエンドは Next.js と `}<a parentName="p" {...{
        "href": "https://www.apollographql.com/"
      }}>{`Apollo`}</a>{` を使って GraphQL にアクセスするという形で全て AWS 上に乗っているという感じですね。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: オーソドックスな構成になっているんですね。何か使いづらいなどはないですか?`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: 使いづらいということはないんですが、フロントエンド側でデータをオーバフェッチしている所があるんで、そこは見直さないといけないと思っているところです。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: フロントエンドの状態管理はどのようにしているんですか?`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://recoiljs.org/"
      }}>{`Recoil`}</a>{` を使っています。ですが、ライブラリの持続可能性を考えて別の方法に移行したほうがよいなとは考えているところですね…。`}</p>
    <h1>{`サブカルテ機能の開発`}</h1>
    <h2>{`サブカルテ機能とは`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: では今回のメインの話である`}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20230328.html"
      }}>{`サブカルテ`}</a>{`機能について、お話を伺えればと思いますが、そもそもサブカルテとはどんなものなんでしょうか。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: 歯科特有の機能になると思うのですが、カルテに記録しない患者に関する情報、たとえばレントゲンや口腔写真などのファイル管理や文書管理などをデジタル化する機能です。デジタルで管理することで、写真やファイルを保管する場所を確保する必要がなくなり、院内空間を有効活用することもできますよね。また、Dentis にあって他社にない機能としては「アクティビティ」でしょうか。院内のスタッフ間で情報共有ができる機能で、Dentis を活用いただいている歯科医院から好評をいただいている機能のひとつです。`}</p>
    <p>{`普通は院内でスタッフの情報共有をする場合は、紙カルテなどに直接書き込みするのですが、Slack のようなタイムライン形式で情報共有できリアクションなども取れるようにしているのが「アクティビティ」機能になります。`}</p>
    <p><strong parentName="p">{`宮内`}</strong>{`: 歯科医院は歯科医師以外にも、歯科衛生士や歯科助手など多数の人達が連携して治療にあたるチーム医療をしているので、このような使い方をしているようです。またサブカルテの内容としては医院独自になっているようなので、Dentis のサブカルテは様々な使い方をリサーチして一般的に使いやすいであろうという形にしています。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: アクティビティなどは、今の形に落ち着くまで機能を考えるのは大変そうですね。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0927c0fc05370ad9ef776df4bad16ded/21b4d/_note010_dev_008.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsTAAALEwEAmpwYAAACyElEQVQozy2QV09TAQCF72/wwfnmABxBRNrYluWICsVgUOMeuC2CxEJCjAuD4I4RZ1UCblEUoQqKUcuqlqo4qFqMUVCQYsvt7bhtNX6mxKfzcvKdIXh9EqLoJDdHx8ihQ5ibrOK11Yw/4MMtDeDxunG7BwiFZNqsFlRxMRzUpdP+5BZe2YfL5UDyuLh+rQLF5EkIf/nD3apKRg0bwpwEJcfzV2FvrkKURKT/QNHtIhiSaW1ponjLAmw3D9BaUcT37i9IHhG35GJXgZ4pEyIRAkE/z83NTBofwbX92fg6HtHzoppfjh+D5jAwrGF4IBTgQ3UpH6/s4W1l6aAnHOaXPZQZThMzfiyC1+vGJ3tZv3IRLZd247TewGa6zYDowvMfFAYGg34amxop0WfSeELHpxYjctCHMzxZctHb282qJQsQwj9VlF8kOTGeU0V5WI1lfP1sQw74Bz8MNwj9DlBXZ2RmUjxJGjXLM7Rkrl5Bc3MTclBGdIuD2mRqQDhQUkxkxDiUSiWKOAXp2hSytmbT09ONz+9hQHTi6O9l04Z1JCdoSJk9C23KXOZrZ3Hq2F6+fjLz3mLEZq2n72cXwpSYGNRqNRqNhqmxU9mWk4tKpSYvT48kOen69hlbxysWL8ogXqMmKTGReampGK8ewnR7Pw/KC6g1ZPOioZz+X30IUVFRqKapiItTEB0dzby0NLbqsoiMiOTZk4d8/2an7flTVKppZMxJJnfNQjKXZlBzqYSbZ/RUnc3hzhkd9ncmHI4ehNFjRjNj+nQ2r9eRtSWXgvxdnCy9QOG+I5w1XKHqbgP1dY+IVSjJSo/Hca+YhrJCzh3Nx1CylqvHN1J9uQhzm5nHLa0Iw0cMR6tNo/a+iftPrZgsHZjbO3n5oYvWN500vvyI5Y0d/fZtxE4cw8Udy6gx7OTI7nWcP6yjtraSZxYr7fZOun728Q+GS0EWSwyEQAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": " note010 dev 008",
            "title": " note010 dev 008",
            "src": "/static/0927c0fc05370ad9ef776df4bad16ded/c1b63/_note010_dev_008.png",
            "srcSet": ["/static/0927c0fc05370ad9ef776df4bad16ded/5a46d/_note010_dev_008.png 300w", "/static/0927c0fc05370ad9ef776df4bad16ded/0a47e/_note010_dev_008.png 600w", "/static/0927c0fc05370ad9ef776df4bad16ded/c1b63/_note010_dev_008.png 1200w", "/static/0927c0fc05370ad9ef776df4bad16ded/21b4d/_note010_dev_008.png 1280w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`前田`}</strong>{`: はい、最初は Markdown を書いていけるような Wiki のような形も考えていたりしたので、タイムライン形式に落ち着くまでは試行錯誤しました。医院のコミュニケーションの仕方によって使用率も違いますが、結構使ってくださっていますね。`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: 画像ファイルにメモなどを残している医院などもあるようで、ワークフローによって使いやすい方を選んでいるようです。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: Dentis は医院のペーパーレス化を促進するプロダクトなので、使い方としては様々ですが紙でのワークフローの感覚でそのまま、もっと便利に使えるという部分を重視しています。`}</p>
    <h2>{`サブカルテ機能の開発背景`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: そんなサブカルテ機能ですが、開発しようとなった背景としてはどんなものがあったんでしょうか。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: Dentis では「シームレスにつながる」というのをひとつのコンセプトとして設計しているのですが、予約〜カルテ〜会計まで機能的につなげても、業務フローとしてはどうしても隙間が生じていて、シームレスにつながってない事に気づいたんですよね。院内スタッフ間でのちょっとしたコミュニケーションもデジタルで管理できれば、その隙間を埋め、歯科医院の DX 化を推進できるのではないかと思い、サブカルテの開発を始めました。先程も言ったように、ペーパーレス化を促進という目的にも合致していましたしね。`}</p>
    <h2>{`技術的に難しかった部分`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 技術的に困難なところなどはありましたか?`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: 手書きメモと組写真機能(撮影した口腔内の画像を 5 枚や 10 枚で組み合わせて表示する歯科の画像表示形式)を Chrome と iPad / Mac Safari に対応させるのは難しかったかもしれません。`}</p>
    <p>{`どれかに対応させると、どれかが上手く表示できないなどがあったので。`}</p>
    <p><strong parentName="p">{`宮内`}</strong>{`: 画像の表示ライブラリを途中で変更したりしましたよね。`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: 最初は `}<a parentName="p" {...{
        "href": "https://konvajs.org/"
      }}>{`Konva`}</a>{` というライブラリだったんですが、ゆっくりペンを動かすと、滑らかな線が書けないという問題が発生しまして…。`}<a parentName="p" {...{
        "href": "http://fabricjs.com/"
      }}>{`Fabric.js`}</a>{` を使うように変更しました。`}</p>
    <h2>{`サブカルテ機能を作ったことによるプロダクトへの良い効果`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。サブカルテ機能は今の形になるまで、どのような検討をしたんでしょうか。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: 元々ファイル管理機能などは既に Dentis であった機能だったんです。そこは活かしつつも、求められる他の機能は何かというところを考えていきながら、様々なサブカルテの活用事例もリサーチしながら、今の形に落ち着いたという感じです。`}</p>
    <p><strong parentName="p">{`大岡`}</strong>{`: 副産物としては今まで Dentis にはなかった「患者詳細」という画面をこのサブカルテ機能を入れるために拡張していったので、その後のオンライン資格確認などへ展開しやすいという状態になりました。情報設計を見直せたおかげです。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: サブカルテ起点で患者検索もさらに詳細にできたりしたので、そうした副産物も大変良かったです。`}</p>
    <h2>{`サブカルテ機能追加による反響`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: 医院からはサブカルテの追加により何か反響はあったんでしょうか。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: ネガティブな意見はあまりないですし、むしろ「追加機能としてこういうのが欲しい」というご意見をいただくので、結構使っていただけていると思います。`}</p>
    <p><strong parentName="p">{`宮内`}</strong>{`: ちょっと興味深かったのが、承認ボタンが欲しいというご意見がありました。医院のワークフローとして、医師以外の方が行なおうとする処置などを「アクティビティ」上で医師に承認してもらうというフローのようです。すぐに導入できるかというとちょっと分からないのですが。`}</p>
    <h2>{`Dentis チームにマッチするメンバー`}</h2>
    <p><strong parentName="p">{`新居`}</strong>{`: ありがとうございます。最後にそんな Dentis チームで一緒に働いてみたいと思うような方はどんな人でしょうか。`}</p>
    <p><strong parentName="p">{`前田`}</strong>{`: 高齢化が進んでおり、そろそろ若い人に来てほしいですね（笑）`}</p>
    <p><strong parentName="p">{`宮内`}</strong>{`: どの領域の開発メンバーでも現在は少人数で運営しているので、入ってほしいなとは思います…。一番欲しいというメンバーでいうと「歯科レセコンに精通した開発者」の方ですかね。`}</p>
    <p><strong parentName="p">{`新居`}</strong>{`: なかなかハードルが高そうな経験ですね（笑） 本日はありがとうございました!`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`Dentis のサブカルテ機能の開発について、開発者に聞いてみました。歯科には歯科独自のワークフローがあり、紙の運用が重視されてきた風潮の中で、業務オペレーションをふまえて開発していくユーザーファーストな開発者の発想やそれを実現する技術アプローチなど、とても面白い開発をしているなと話を聞いていて思いました。
Dentis の開発チームはベテラン揃いなので、彼らと肩を並べて働くことで、自身の成長を加速させる絶好の機会になると思います。`}</p>
    <p>{`Dentis の開発に興味を持った方はぜひご連絡お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      